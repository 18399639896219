<template>
  <div
    class="w-full min-h-screen grid grid-cols-1 lg:grid-cols-2 bg-noise bg-grayscale-2"
  >
    <div class="flex flex-col justify-center items-center p-6">
      <img
        src="https://ik.imagekit.io/xposuremusic/full-logo.svg"
        alt="xposure-logo"
      />
      <div class="max-w-sm w-full z-10">
        <ClientOnly>
          <LoginForm
            @userAuthenticated="userAuthenticated"
            @changeAuthType="changeAuthType"
          />
        </ClientOnly>
      </div>
    </div>
    <div
      style="
        background-image: url(https://ik.imagekit.io/xposuremusic/login.png);
      "
      class="bg-cover hidden lg:block"
    />
  </div>
</template>

<script setup lang="ts">
import LoginForm from "@/components/authentication/LoginForm.vue";
import PageTitles from "../assets/strings";
import { useAuthenticationStore } from "../store/authentication";
import { AuthActionTypes } from "../types/authentication";

useSessionStorage("showHeader", true).value = false;

const router = useRouter();

const authStore = useAuthenticationStore();

let redirectUrl: string | null = null;

onMounted(() => {
  redirectUrl = localStorage.getItem("redirectAfterSignIn");
});

const userAuthenticated = () => {
  router.push(redirectUrl ?? "/dashboard");
};

const changeAuthType = (authActionType: AuthActionTypes) => {
  if (authActionType === AuthActionTypes.forgotPassword) {
    authStore.setModalState({
      isModalVisible: true,
      defaultActionType: AuthActionTypes.forgotPassword,
    });
    router.push("/");
    return;
  }
  router.push("/signup");
};

if (!process.server) {
  const isLogged = computed(() => Boolean(authStore.authUser?.authToken));
  if (isLogged.value) {
    router.push("/dashboard");
  }
}

useHead({
  title: PageTitles.login.title,
  meta: [
    {
      name: "description",
      content: PageTitles.login.description,
    },
    {
      name: "og:description",
      content: PageTitles.login.description,
    },
    {
      name: "twitter:description",
      content: PageTitles.login.description,
    },
    {
      property: "og:image",
      content: "https://ik.imagekit.io/xposuremusic/login_og.png",
    },
    {
      property: "twitter:image",
      content: "https://ik.imagekit.io/xposuremusic/login_og.png",
    },
    {
      property: "og:image:secure_url",
      content: "https://ik.imagekit.io/xposuremusic/login_og.png",
    },
    {
      property: "og:image:alt",
      content: PageTitles.login.title,
    },
    {
      property: "og:image:type",
      content: "image/svg",
    },
    {
      property: "og:image:width",
      content: "512",
    },
    {
      property: "og:image:height",
      content: "512",
    },
    {
      property: "og:title",
      content: PageTitles.login.title,
    },
    {
      property: "twitter:title",
      content: PageTitles.login.title,
    },
  ],
});
</script>
